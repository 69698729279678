import React, {
  useContext,
  createContext,
  useState,
  useRef,
  useEffect,
  ComponentType,
  Dispatch,
  SetStateAction,
} from "react";
import { compact } from "lodash/fp";
import { UserSelf } from "storefront/User";
import { Event } from "storefront/Analytics/Event";
import { Tracker, empty } from "storefront/Analytics/Tracker";
import { ADVERTISING } from "storefront/lib/OneTrust/Categories";
import initialize from "storefront/Analytics/initialize";
import Properties from "storefront/Analytics/Properties";
import { getInstance as getPinterestTrackerInstance } from "storefront/Analytics/PinterestTracker";
import { getInstance as getGoogleAdsTrackerInstance } from "storefront/Analytics/GoogleAdsTracker";
import { getInstance as getSegmentTrackerInstance } from "storefront/Analytics/SegmentTracker";
import { getInstance as getSiftScienceTrackerInstance } from "storefront/Analytics/SiftScienceTracker";
import { getInstance as getAlgoliaInsightsTrackerInstance } from "storefront/Analytics/AlgoliaInsightsTracker";
import { getInstance as getTikTokTrackerInstance } from "storefront/Analytics/TikTokTracker";
import { getInstance as getSnapchatTrackerInstance } from "storefront/Analytics/SnapchatTracker";

export const Context = createContext<Tracker>(empty);
Context.displayName = "AnalyticsContext";

let timeoutInstance: NodeJS.Timeout;

const clear = (tm: NodeJS.Timeout) => {
  if (tm) clearTimeout(tm);
};

const setTrackers = (setTracker: Dispatch<SetStateAction<Tracker>>) => {
  if (
    window.OnetrustActiveGroups &&
    compact(window.OnetrustActiveGroups.split(",")).length !== 0
  ) {
    const enabledGroups = window.OnetrustActiveGroups.split(",");

    let enabledTrackers = [getSegmentTrackerInstance(enabledGroups)];

    if (enabledGroups.includes(ADVERTISING)) {
      gtag("consent", "update", {
        ad_personalization: "granted",
        ad_storage: "granted",
        ad_user_data: "granted",
        analytics_storage: "granted",
      });

      enabledTrackers = [
        ...enabledTrackers,
        getPinterestTrackerInstance(),
        getGoogleAdsTrackerInstance(),
        getSiftScienceTrackerInstance(),
        getAlgoliaInsightsTrackerInstance(),
        getTikTokTrackerInstance(),
        getSnapchatTrackerInstance(),
      ];
    }

    clear(timeoutInstance);
    setTracker(initialize(enabledTrackers));
    return;
  }

  clear(timeoutInstance);

  timeoutInstance = setTimeout(() => {
    setTrackers(setTracker);
  }, 500);
};

export const Provider = ({ children }: { children: React.ReactNode }) => {
  const events = useRef<Array<Event>>([]);
  const identity = useRef<[UserSelf | undefined, Properties | undefined]>([
    undefined,
    undefined,
  ]);

  const defaultTracker: Tracker = {
    track: (event) => {
      events.current.push(event);
      return event;
    },
    identify: (user, properties) => {
      identity.current = [user, properties];
      return user;
    },
  };

  const [tracker, setTracker] = useState<Tracker>(defaultTracker);

  useEffect(() => {
    setTrackers(setTracker);
  }, []);

  useEffect(() => {
    const [user, properties] = identity.current;
    if (user) tracker.identify(user, properties);
    events.current.forEach(tracker.track);
    events.current = [];
  }, [tracker]);

  return <Context.Provider value={tracker}>{children}</Context.Provider>;
};

Provider.displayName = "AnalyticsProvider";

const useAnalytics = (): Tracker => useContext(Context);

export type WithAnalyticsProps = {
  tracker: Tracker;
};

export function withAnalytics<OwnProps>(
  Component: ComponentType<OwnProps & WithAnalyticsProps>,
): ComponentType<OwnProps> {
  return (props) => {
    const tracker = useAnalytics();
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...props} tracker={tracker} />;
  };
}

export default useAnalytics;

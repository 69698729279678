import { isEmpty } from "lodash/fp";
import type { Followings } from "storefront/Followings";
import type { Code as ShippingRegionCode } from "storefront/Shipping/Region/Code";
import type { Department } from "storefront/Department";
import { GrailedAPIDesigner } from "storefront/Designer";
import { empty as emptySellerScore } from "../SellerScore";
import { empty as emptyShippingAddress } from "../PostalAddress";
import { empty as emptyShippingValues } from "../Shipping/Values";
import type { Id } from "../lib/Id";
import { emptyId } from "../lib/Id";
import type { IndexedList } from "../lib/IndexedList";
import type { Values as ShippingValues } from "../Shipping/Values";
import type { SellerScore } from "../SellerScore";
import type { ShippingAddress } from "../PostalAddress";
import type { UserHeatPenalty } from "../UserHeatPenalty";
import type { Name as ShippingRegionName } from "../Shipping/Region";
import type { Vacation } from "../Vacation";
import type { PowerSellerGroup } from "../PowerSellerGroup";
import { BuyerScore } from "../BuyerScore";

export type FollowedListings = Array<Id>;

export const StripeActive = "active" as const;
export const StripeInactive = "inactive" as const;
export const StripeNotConnected = "not_connected" as const;

export type StripeOnboardingStatus =
  | typeof StripeActive
  | typeof StripeInactive
  | typeof StripeNotConnected;

export type User = {
  id: Id;
  username: string;
  accountPreferences: null | AccountPreferences;
  avatarUrl?: string;
  avatar_url?: string;
  totalBoughtAndSold: number;
  buyerScore: BuyerScore;
  sellerScore: SellerScore;
  group: string;
  isBlocked: boolean;
  isFollowed: boolean;
  isAdmin: boolean;
  needsToConnectPaypalMerchantAccount: boolean;
  hasMerchantAccount: boolean;
  height?: number | null;
  weight?: number | null;
  isBanned?: boolean;
  isDeleted?: boolean;
  isConfirmed?: boolean;
  isAuthedSeller?: boolean;
  isManagedSeller?: boolean;
  isVerified?: boolean;
  isCurator?: boolean;
  vacation: Vacation | null | undefined;
  isQuickResponder?: boolean;
  isSpeedyShipper?: boolean;
  isTrustedSeller?: boolean;
  email: string;
  listingsForSaleCount?: number;
  followedListings: FollowedListings;
  followerCount: number;
  followingCount?: number;
  following?: Followings;
  location: ShippingRegionName;
  locationAbbreviation: ShippingRegionCode;
  paypalMerchantId?: string;
  hasUnacknowledgedViolations?: boolean;
  createdAt?: string;
  sellerBadgesRevoked?: boolean;
  trustScore?: string;
  userHeatPenalties?: Array<UserHeatPenalty>;
  powerSellerGroup?: PowerSellerGroup;
  topDesigners?: GrailedAPIDesigner[];
};

export const empty: User = {
  id: emptyId,
  username: "",
  accountPreferences: null,
  totalBoughtAndSold: 0,
  buyerScore: {
    purchaseCount: 0,
    wouldSellToAgainCount: 0,
  },
  sellerScore: emptySellerScore,
  group: "",
  isBlocked: false,
  isFollowed: false,
  isAdmin: false,
  needsToConnectPaypalMerchantAccount: false,
  hasMerchantAccount: false,
  email: "",
  followedListings: [],
  followerCount: 0,
  location: "United States",
  locationAbbreviation: "us",
  vacation: null,
  topDesigners: [],
};
export const loadingUser: User = {
  ...empty,
  username: "loading...",
  group: "loading...",
  email: "loading...",
};
export const nullUser: User = {
  ...empty,
  username: "User Not Found",
  group: "User Not Found",
  email: "User Not Found",
};

export type LabelPrintSize = "standard" | "thermal";

export type AccountPreferences = {
  departments: Array<Department>;
  labelPrintSize: LabelPrintSize;
  expeditedShipping: boolean;
};

// The type of user returned by the User::To.self_api_json call, including
// things that are only present for your own user
export type UserSelf = User & {
  is2faVerified: boolean;
  shippingAddress: ShippingAddress;
  shouldSellThroughPaypalRest: boolean;
  betas: Array<string>;
  hasBillingAgreement: boolean;
  blocked: IndexedList<Array<string>>;
  defaultShippingCosts: ShippingValues;
  countryCode: string | null;
  expeditedShippingSuspended: boolean;
  stripeConnectedAccountId?: string;
  stripeOnboardingEligible: boolean;
  stripeOnboardingStatus: StripeOnboardingStatus;
};

export const emptyUserSelf: UserSelf = {
  ...empty,
  is2faVerified: false,
  shippingAddress: emptyShippingAddress,
  shouldSellThroughPaypalRest: false,
  betas: [],
  hasBillingAgreement: false,
  blocked: {},
  defaultShippingCosts: emptyShippingValues,
  countryCode: null,
  expeditedShippingSuspended: false,
  stripeOnboardingEligible: false,
  stripeOnboardingStatus: StripeNotConnected,
};

export const loadingUserSelf = { ...loadingUser, ...emptyUserSelf };
// Selectors
export const id = (user: User): Id => user.id;
export const avatarUrl = (user: User): string | null | undefined =>
  user.avatarUrl;
export const isBlocked = (user: User): boolean => user.isBlocked;
export const username = (user: User): string => user.username;
export const isConfirmed = (user: User): boolean => !!user.isConfirmed;
export const isAdmin = (user: User): boolean => !!user.isAdmin;
export const isCurator = (user: User): boolean => !!user.isCurator;
export const isOnVacation = (user: User): boolean => !isEmpty(user.vacation);
export const wardrobePath: (arg0: User) => string = (user) => {
  const base = `${window.location.protocol}//${window.location.host}`;
  const path = `${user.username}`;
  return `${base}/${path}`;
};

export const formatLocation = (user: User): string =>
  user.location || "No location set";
